

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.ceu,.tempestade,.chuva,.sol,.trovao,.nevoa,.nuvem{
  border-radius: 10px;
  padding: 10px;
}
.ceu{
  background-color: orange;
}
.tempestade{
  background-color: red;
}
.chuva{
background-color: blue;
}
.sol{
  background-color: yellow;
}
.trovao{
  background-color: greenyellow;
}
.nevoa{
  background-color: royalblue;
}
.nuvem{
  background-color: orchid;
}
img{
  width: 200px;
  height: 200px;
}
h2{
  margin-top: 0px;
}
.cards{
  display: flex;
  flex-wrap: wrap;
}
p{
  font-size: medium;
}